import { default as certificateAaU7w07hQTMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/account/certificate.vue?macro=true";
import { default as collectpApId0TbzeMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/account/collect.vue?macro=true";
import { default as couponnt1BkbbH0EMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/account/coupon.vue?macro=true";
import { default as courseB7myIAEPk0Meta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/account/course.vue?macro=true";
import { default as gradeaxcmpMkP3MMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/account/grade.vue?macro=true";
import { default as infoLdoAUND6IgMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/account/info.vue?macro=true";
import { default as indexX8k8a7V6CyMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/account/message/index.vue?macro=true";
import { default as noteWlzjeKjhpmMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/account/note.vue?macro=true";
import { default as indexvcoC0Qiio9Meta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/account/order/index.vue?macro=true";
import { default as paperq4ShUYPrX1Meta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/account/paper.vue?macro=true";
import { default as listmjQZKocfkBMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/article/list.vue?macro=true";
import { default as viewSeRiqVZHCgMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/article/view.vue?macro=true";
import { default as audios5BXysUgIBMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/course/audio.vue?macro=true";
import { default as combineJjS6WlCa3eMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/course/combine.vue?macro=true";
import { default as graphicHNUnpbKmGiMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/course/graphic.vue?macro=true";
import { default as list4d1G57QStlMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/course/list.vue?macro=true";
import { default as liveHn2o2hlyASMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/course/live.vue?macro=true";
import { default as singleh9Syp3LGlTMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/course/single.vue?macro=true";
import { default as subjectsYWtOBJajMMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/course/subject.vue?macro=true";
import { default as topicUfUYbgl74rMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/course/topic.vue?macro=true";
import { default as videoiX7qzCTphWMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/course/video.vue?macro=true";
import { default as analysisLVxTiyiiIvMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/exam/analysis.vue?macro=true";
import { default as emigratedDwBqBjLpOSMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/exam/emigrated.vue?macro=true";
import { default as indexVEPzbvLyVdMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/exam/index.vue?macro=true";
import { default as list58tvC71fCkMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/exam/list.vue?macro=true";
import { default as result82HEFEqxsxMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/exam/result.vue?macro=true";
import { default as startExamjWScXXCFRAMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/exam/startExam.vue?macro=true";
import { default as homework9SXCh0q6PWMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/grade/homework.vue?macro=true";
import { default as list39K7iqu4g8Meta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/grade/list.vue?macro=true";
import { default as practicekZWNyBqKSaMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/grade/practice.vue?macro=true";
import { default as viewIa88tDyd3gMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/grade/view.vue?macro=true";
import { default as index28itYwhpnLMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/index.vue?macro=true";
import { default as agreementT4ZwlxmaiDMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/login/agreement.vue?macro=true";
import { default as indexIuGxcvTX10Meta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/login/index.vue?macro=true";
import { default as emigratedXdTCtmqIPEMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/paper/emigrated.vue?macro=true";
import { default as indexDptm2xlfpUMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/paper/index.vue?macro=true";
import { default as listFmtd1jTKWIMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/paper/list.vue?macro=true";
import { default as view1Dd1a6YkJJMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/paper/view.vue?macro=true";
import { default as buyFxpKMjOefIMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/pay/buy.vue?macro=true";
import { default as paySuccessDWjgRkAmWfMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/pay/paySuccess.vue?macro=true";
import { default as analysismJW5zd4i0JMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/practice/analysis.vue?macro=true";
import { default as daily46kMr9kMNBMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/practice/daily.vue?macro=true";
import { default as dailyProblemGMRSLRtqxRMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/practice/dailyProblem.vue?macro=true";
import { default as errorrLJgUIJkFpMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/practice/error.vue?macro=true";
import { default as errorAnalysisOhOKSr7jwDMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/practice/errorAnalysis.vue?macro=true";
import { default as errorProblemWPqs4Dh7q6Meta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/practice/errorProblem.vue?macro=true";
import { default as errorResultDd9nJM7wlRMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/practice/errorResult.vue?macro=true";
import { default as resultkcIYzQ6Ta0Meta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/practice/result.vue?macro=true";
import { default as startPracticesiSvy9ISRxMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/practice/startPractice.vue?macro=true";
import { default as viewbNG9m8Z0bNMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/practice/view.vue?macro=true";
import { default as indexjhEMGTrP0WMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/register/index.vue?macro=true";
import { default as indext07RWb031xMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/resetpwd/index.vue?macro=true";
import { default as index0CaA51JrIYMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/search/index.vue?macro=true";
import { default as listbev0QQwLu8Meta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/teacher/list.vue?macro=true";
import { default as viewdW6PB5dTKPMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/teacher/view.vue?macro=true";
import { default as indexnHU00shtWxMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/vip/index.vue?macro=true";
import { default as successMaDJMGov1KMeta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/wx/success.vue?macro=true";
import { default as wxoLvAfqunM7Meta } from "/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/wx/wx.vue?macro=true";
export default [
  {
    name: certificateAaU7w07hQTMeta?.name ?? "account-certificate",
    path: certificateAaU7w07hQTMeta?.path ?? "/account/certificate",
    meta: certificateAaU7w07hQTMeta || {},
    alias: certificateAaU7w07hQTMeta?.alias || [],
    redirect: certificateAaU7w07hQTMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/account/certificate.vue").then(m => m.default || m)
  },
  {
    name: collectpApId0TbzeMeta?.name ?? "account-collect",
    path: collectpApId0TbzeMeta?.path ?? "/account/collect",
    meta: collectpApId0TbzeMeta || {},
    alias: collectpApId0TbzeMeta?.alias || [],
    redirect: collectpApId0TbzeMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/account/collect.vue").then(m => m.default || m)
  },
  {
    name: couponnt1BkbbH0EMeta?.name ?? "account-coupon",
    path: couponnt1BkbbH0EMeta?.path ?? "/account/coupon",
    meta: couponnt1BkbbH0EMeta || {},
    alias: couponnt1BkbbH0EMeta?.alias || [],
    redirect: couponnt1BkbbH0EMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/account/coupon.vue").then(m => m.default || m)
  },
  {
    name: courseB7myIAEPk0Meta?.name ?? "account-course",
    path: courseB7myIAEPk0Meta?.path ?? "/account/course",
    meta: courseB7myIAEPk0Meta || {},
    alias: courseB7myIAEPk0Meta?.alias || [],
    redirect: courseB7myIAEPk0Meta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/account/course.vue").then(m => m.default || m)
  },
  {
    name: gradeaxcmpMkP3MMeta?.name ?? "account-grade",
    path: gradeaxcmpMkP3MMeta?.path ?? "/account/grade",
    meta: gradeaxcmpMkP3MMeta || {},
    alias: gradeaxcmpMkP3MMeta?.alias || [],
    redirect: gradeaxcmpMkP3MMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/account/grade.vue").then(m => m.default || m)
  },
  {
    name: infoLdoAUND6IgMeta?.name ?? "account-info",
    path: infoLdoAUND6IgMeta?.path ?? "/account/info",
    meta: infoLdoAUND6IgMeta || {},
    alias: infoLdoAUND6IgMeta?.alias || [],
    redirect: infoLdoAUND6IgMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/account/info.vue").then(m => m.default || m)
  },
  {
    name: indexX8k8a7V6CyMeta?.name ?? "account-message",
    path: indexX8k8a7V6CyMeta?.path ?? "/account/message",
    meta: indexX8k8a7V6CyMeta || {},
    alias: indexX8k8a7V6CyMeta?.alias || [],
    redirect: indexX8k8a7V6CyMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/account/message/index.vue").then(m => m.default || m)
  },
  {
    name: noteWlzjeKjhpmMeta?.name ?? "account-note",
    path: noteWlzjeKjhpmMeta?.path ?? "/account/note",
    meta: noteWlzjeKjhpmMeta || {},
    alias: noteWlzjeKjhpmMeta?.alias || [],
    redirect: noteWlzjeKjhpmMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/account/note.vue").then(m => m.default || m)
  },
  {
    name: indexvcoC0Qiio9Meta?.name ?? "account-order",
    path: indexvcoC0Qiio9Meta?.path ?? "/account/order",
    meta: indexvcoC0Qiio9Meta || {},
    alias: indexvcoC0Qiio9Meta?.alias || [],
    redirect: indexvcoC0Qiio9Meta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: paperq4ShUYPrX1Meta?.name ?? "account-paper",
    path: paperq4ShUYPrX1Meta?.path ?? "/account/paper",
    meta: paperq4ShUYPrX1Meta || {},
    alias: paperq4ShUYPrX1Meta?.alias || [],
    redirect: paperq4ShUYPrX1Meta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/account/paper.vue").then(m => m.default || m)
  },
  {
    name: listmjQZKocfkBMeta?.name ?? "article-list",
    path: listmjQZKocfkBMeta?.path ?? "/article/list",
    meta: listmjQZKocfkBMeta || {},
    alias: listmjQZKocfkBMeta?.alias || [],
    redirect: listmjQZKocfkBMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/article/list.vue").then(m => m.default || m)
  },
  {
    name: viewSeRiqVZHCgMeta?.name ?? "article-view",
    path: viewSeRiqVZHCgMeta?.path ?? "/article/view",
    meta: viewSeRiqVZHCgMeta || {},
    alias: viewSeRiqVZHCgMeta?.alias || [],
    redirect: viewSeRiqVZHCgMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/article/view.vue").then(m => m.default || m)
  },
  {
    name: audios5BXysUgIBMeta?.name ?? "course-audio",
    path: audios5BXysUgIBMeta?.path ?? "/course/audio",
    meta: audios5BXysUgIBMeta || {},
    alias: audios5BXysUgIBMeta?.alias || [],
    redirect: audios5BXysUgIBMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/course/audio.vue").then(m => m.default || m)
  },
  {
    name: combineJjS6WlCa3eMeta?.name ?? "course-combine",
    path: combineJjS6WlCa3eMeta?.path ?? "/course/combine",
    meta: combineJjS6WlCa3eMeta || {},
    alias: combineJjS6WlCa3eMeta?.alias || [],
    redirect: combineJjS6WlCa3eMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/course/combine.vue").then(m => m.default || m)
  },
  {
    name: graphicHNUnpbKmGiMeta?.name ?? "course-graphic",
    path: graphicHNUnpbKmGiMeta?.path ?? "/course/graphic",
    meta: graphicHNUnpbKmGiMeta || {},
    alias: graphicHNUnpbKmGiMeta?.alias || [],
    redirect: graphicHNUnpbKmGiMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/course/graphic.vue").then(m => m.default || m)
  },
  {
    name: list4d1G57QStlMeta?.name ?? "course-list",
    path: list4d1G57QStlMeta?.path ?? "/course/list",
    meta: list4d1G57QStlMeta || {},
    alias: list4d1G57QStlMeta?.alias || [],
    redirect: list4d1G57QStlMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/course/list.vue").then(m => m.default || m)
  },
  {
    name: liveHn2o2hlyASMeta?.name ?? "course-live",
    path: liveHn2o2hlyASMeta?.path ?? "/course/live",
    meta: liveHn2o2hlyASMeta || {},
    alias: liveHn2o2hlyASMeta?.alias || [],
    redirect: liveHn2o2hlyASMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/course/live.vue").then(m => m.default || m)
  },
  {
    name: singleh9Syp3LGlTMeta?.name ?? "course-single",
    path: singleh9Syp3LGlTMeta?.path ?? "/course/single",
    meta: singleh9Syp3LGlTMeta || {},
    alias: singleh9Syp3LGlTMeta?.alias || [],
    redirect: singleh9Syp3LGlTMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/course/single.vue").then(m => m.default || m)
  },
  {
    name: subjectsYWtOBJajMMeta?.name ?? "course-subject",
    path: subjectsYWtOBJajMMeta?.path ?? "/course/subject",
    meta: subjectsYWtOBJajMMeta || {},
    alias: subjectsYWtOBJajMMeta?.alias || [],
    redirect: subjectsYWtOBJajMMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/course/subject.vue").then(m => m.default || m)
  },
  {
    name: topicUfUYbgl74rMeta?.name ?? "course-topic",
    path: topicUfUYbgl74rMeta?.path ?? "/course/topic",
    meta: topicUfUYbgl74rMeta || {},
    alias: topicUfUYbgl74rMeta?.alias || [],
    redirect: topicUfUYbgl74rMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/course/topic.vue").then(m => m.default || m)
  },
  {
    name: videoiX7qzCTphWMeta?.name ?? "course-video",
    path: videoiX7qzCTphWMeta?.path ?? "/course/video",
    meta: videoiX7qzCTphWMeta || {},
    alias: videoiX7qzCTphWMeta?.alias || [],
    redirect: videoiX7qzCTphWMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/course/video.vue").then(m => m.default || m)
  },
  {
    name: analysisLVxTiyiiIvMeta?.name ?? "exam-analysis",
    path: analysisLVxTiyiiIvMeta?.path ?? "/exam/analysis",
    meta: analysisLVxTiyiiIvMeta || {},
    alias: analysisLVxTiyiiIvMeta?.alias || [],
    redirect: analysisLVxTiyiiIvMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/exam/analysis.vue").then(m => m.default || m)
  },
  {
    name: emigratedDwBqBjLpOSMeta?.name ?? "exam-emigrated",
    path: emigratedDwBqBjLpOSMeta?.path ?? "/exam/emigrated",
    meta: emigratedDwBqBjLpOSMeta || {},
    alias: emigratedDwBqBjLpOSMeta?.alias || [],
    redirect: emigratedDwBqBjLpOSMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/exam/emigrated.vue").then(m => m.default || m)
  },
  {
    name: indexVEPzbvLyVdMeta?.name ?? "exam",
    path: indexVEPzbvLyVdMeta?.path ?? "/exam",
    meta: indexVEPzbvLyVdMeta || {},
    alias: indexVEPzbvLyVdMeta?.alias || [],
    redirect: indexVEPzbvLyVdMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/exam/index.vue").then(m => m.default || m)
  },
  {
    name: list58tvC71fCkMeta?.name ?? "exam-list",
    path: list58tvC71fCkMeta?.path ?? "/exam/list",
    meta: list58tvC71fCkMeta || {},
    alias: list58tvC71fCkMeta?.alias || [],
    redirect: list58tvC71fCkMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/exam/list.vue").then(m => m.default || m)
  },
  {
    name: result82HEFEqxsxMeta?.name ?? "exam-result",
    path: result82HEFEqxsxMeta?.path ?? "/exam/result",
    meta: result82HEFEqxsxMeta || {},
    alias: result82HEFEqxsxMeta?.alias || [],
    redirect: result82HEFEqxsxMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/exam/result.vue").then(m => m.default || m)
  },
  {
    name: startExamjWScXXCFRAMeta?.name ?? "exam-startExam",
    path: startExamjWScXXCFRAMeta?.path ?? "/exam/startExam",
    meta: startExamjWScXXCFRAMeta || {},
    alias: startExamjWScXXCFRAMeta?.alias || [],
    redirect: startExamjWScXXCFRAMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/exam/startExam.vue").then(m => m.default || m)
  },
  {
    name: homework9SXCh0q6PWMeta?.name ?? "grade-homework",
    path: homework9SXCh0q6PWMeta?.path ?? "/grade/homework",
    meta: homework9SXCh0q6PWMeta || {},
    alias: homework9SXCh0q6PWMeta?.alias || [],
    redirect: homework9SXCh0q6PWMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/grade/homework.vue").then(m => m.default || m)
  },
  {
    name: list39K7iqu4g8Meta?.name ?? "grade-list",
    path: list39K7iqu4g8Meta?.path ?? "/grade/list",
    meta: list39K7iqu4g8Meta || {},
    alias: list39K7iqu4g8Meta?.alias || [],
    redirect: list39K7iqu4g8Meta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/grade/list.vue").then(m => m.default || m)
  },
  {
    name: practicekZWNyBqKSaMeta?.name ?? "grade-practice",
    path: practicekZWNyBqKSaMeta?.path ?? "/grade/practice",
    meta: practicekZWNyBqKSaMeta || {},
    alias: practicekZWNyBqKSaMeta?.alias || [],
    redirect: practicekZWNyBqKSaMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/grade/practice.vue").then(m => m.default || m)
  },
  {
    name: viewIa88tDyd3gMeta?.name ?? "grade-view",
    path: viewIa88tDyd3gMeta?.path ?? "/grade/view",
    meta: viewIa88tDyd3gMeta || {},
    alias: viewIa88tDyd3gMeta?.alias || [],
    redirect: viewIa88tDyd3gMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/grade/view.vue").then(m => m.default || m)
  },
  {
    name: index28itYwhpnLMeta?.name ?? "index",
    path: index28itYwhpnLMeta?.path ?? "/",
    meta: index28itYwhpnLMeta || {},
    alias: index28itYwhpnLMeta?.alias || [],
    redirect: index28itYwhpnLMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: agreementT4ZwlxmaiDMeta?.name ?? "login-agreement",
    path: agreementT4ZwlxmaiDMeta?.path ?? "/login/agreement",
    meta: agreementT4ZwlxmaiDMeta || {},
    alias: agreementT4ZwlxmaiDMeta?.alias || [],
    redirect: agreementT4ZwlxmaiDMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/login/agreement.vue").then(m => m.default || m)
  },
  {
    name: indexIuGxcvTX10Meta?.name ?? "login",
    path: indexIuGxcvTX10Meta?.path ?? "/login",
    meta: indexIuGxcvTX10Meta || {},
    alias: indexIuGxcvTX10Meta?.alias || [],
    redirect: indexIuGxcvTX10Meta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: emigratedXdTCtmqIPEMeta?.name ?? "paper-emigrated",
    path: emigratedXdTCtmqIPEMeta?.path ?? "/paper/emigrated",
    meta: emigratedXdTCtmqIPEMeta || {},
    alias: emigratedXdTCtmqIPEMeta?.alias || [],
    redirect: emigratedXdTCtmqIPEMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/paper/emigrated.vue").then(m => m.default || m)
  },
  {
    name: indexDptm2xlfpUMeta?.name ?? "paper",
    path: indexDptm2xlfpUMeta?.path ?? "/paper",
    meta: indexDptm2xlfpUMeta || {},
    alias: indexDptm2xlfpUMeta?.alias || [],
    redirect: indexDptm2xlfpUMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/paper/index.vue").then(m => m.default || m)
  },
  {
    name: listFmtd1jTKWIMeta?.name ?? "paper-list",
    path: listFmtd1jTKWIMeta?.path ?? "/paper/list",
    meta: listFmtd1jTKWIMeta || {},
    alias: listFmtd1jTKWIMeta?.alias || [],
    redirect: listFmtd1jTKWIMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/paper/list.vue").then(m => m.default || m)
  },
  {
    name: view1Dd1a6YkJJMeta?.name ?? "paper-view",
    path: view1Dd1a6YkJJMeta?.path ?? "/paper/view",
    meta: view1Dd1a6YkJJMeta || {},
    alias: view1Dd1a6YkJJMeta?.alias || [],
    redirect: view1Dd1a6YkJJMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/paper/view.vue").then(m => m.default || m)
  },
  {
    name: buyFxpKMjOefIMeta?.name ?? "pay-buy",
    path: buyFxpKMjOefIMeta?.path ?? "/pay/buy",
    meta: buyFxpKMjOefIMeta || {},
    alias: buyFxpKMjOefIMeta?.alias || [],
    redirect: buyFxpKMjOefIMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/pay/buy.vue").then(m => m.default || m)
  },
  {
    name: paySuccessDWjgRkAmWfMeta?.name ?? "pay-paySuccess",
    path: paySuccessDWjgRkAmWfMeta?.path ?? "/pay/paySuccess",
    meta: paySuccessDWjgRkAmWfMeta || {},
    alias: paySuccessDWjgRkAmWfMeta?.alias || [],
    redirect: paySuccessDWjgRkAmWfMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/pay/paySuccess.vue").then(m => m.default || m)
  },
  {
    name: analysismJW5zd4i0JMeta?.name ?? "practice-analysis",
    path: analysismJW5zd4i0JMeta?.path ?? "/practice/analysis",
    meta: analysismJW5zd4i0JMeta || {},
    alias: analysismJW5zd4i0JMeta?.alias || [],
    redirect: analysismJW5zd4i0JMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/practice/analysis.vue").then(m => m.default || m)
  },
  {
    name: daily46kMr9kMNBMeta?.name ?? "practice-daily",
    path: daily46kMr9kMNBMeta?.path ?? "/practice/daily",
    meta: daily46kMr9kMNBMeta || {},
    alias: daily46kMr9kMNBMeta?.alias || [],
    redirect: daily46kMr9kMNBMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/practice/daily.vue").then(m => m.default || m)
  },
  {
    name: dailyProblemGMRSLRtqxRMeta?.name ?? "practice-dailyProblem",
    path: dailyProblemGMRSLRtqxRMeta?.path ?? "/practice/dailyProblem",
    meta: dailyProblemGMRSLRtqxRMeta || {},
    alias: dailyProblemGMRSLRtqxRMeta?.alias || [],
    redirect: dailyProblemGMRSLRtqxRMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/practice/dailyProblem.vue").then(m => m.default || m)
  },
  {
    name: errorrLJgUIJkFpMeta?.name ?? "practice-error",
    path: errorrLJgUIJkFpMeta?.path ?? "/practice/error",
    meta: errorrLJgUIJkFpMeta || {},
    alias: errorrLJgUIJkFpMeta?.alias || [],
    redirect: errorrLJgUIJkFpMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/practice/error.vue").then(m => m.default || m)
  },
  {
    name: errorAnalysisOhOKSr7jwDMeta?.name ?? "practice-errorAnalysis",
    path: errorAnalysisOhOKSr7jwDMeta?.path ?? "/practice/errorAnalysis",
    meta: errorAnalysisOhOKSr7jwDMeta || {},
    alias: errorAnalysisOhOKSr7jwDMeta?.alias || [],
    redirect: errorAnalysisOhOKSr7jwDMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/practice/errorAnalysis.vue").then(m => m.default || m)
  },
  {
    name: errorProblemWPqs4Dh7q6Meta?.name ?? "practice-errorProblem",
    path: errorProblemWPqs4Dh7q6Meta?.path ?? "/practice/errorProblem",
    meta: errorProblemWPqs4Dh7q6Meta || {},
    alias: errorProblemWPqs4Dh7q6Meta?.alias || [],
    redirect: errorProblemWPqs4Dh7q6Meta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/practice/errorProblem.vue").then(m => m.default || m)
  },
  {
    name: errorResultDd9nJM7wlRMeta?.name ?? "practice-errorResult",
    path: errorResultDd9nJM7wlRMeta?.path ?? "/practice/errorResult",
    meta: errorResultDd9nJM7wlRMeta || {},
    alias: errorResultDd9nJM7wlRMeta?.alias || [],
    redirect: errorResultDd9nJM7wlRMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/practice/errorResult.vue").then(m => m.default || m)
  },
  {
    name: resultkcIYzQ6Ta0Meta?.name ?? "practice-result",
    path: resultkcIYzQ6Ta0Meta?.path ?? "/practice/result",
    meta: resultkcIYzQ6Ta0Meta || {},
    alias: resultkcIYzQ6Ta0Meta?.alias || [],
    redirect: resultkcIYzQ6Ta0Meta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/practice/result.vue").then(m => m.default || m)
  },
  {
    name: startPracticesiSvy9ISRxMeta?.name ?? "practice-startPractice",
    path: startPracticesiSvy9ISRxMeta?.path ?? "/practice/startPractice",
    meta: startPracticesiSvy9ISRxMeta || {},
    alias: startPracticesiSvy9ISRxMeta?.alias || [],
    redirect: startPracticesiSvy9ISRxMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/practice/startPractice.vue").then(m => m.default || m)
  },
  {
    name: viewbNG9m8Z0bNMeta?.name ?? "practice-view",
    path: viewbNG9m8Z0bNMeta?.path ?? "/practice/view",
    meta: viewbNG9m8Z0bNMeta || {},
    alias: viewbNG9m8Z0bNMeta?.alias || [],
    redirect: viewbNG9m8Z0bNMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/practice/view.vue").then(m => m.default || m)
  },
  {
    name: indexjhEMGTrP0WMeta?.name ?? "register",
    path: indexjhEMGTrP0WMeta?.path ?? "/register",
    meta: indexjhEMGTrP0WMeta || {},
    alias: indexjhEMGTrP0WMeta?.alias || [],
    redirect: indexjhEMGTrP0WMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indext07RWb031xMeta?.name ?? "resetpwd",
    path: indext07RWb031xMeta?.path ?? "/resetpwd",
    meta: indext07RWb031xMeta || {},
    alias: indext07RWb031xMeta?.alias || [],
    redirect: indext07RWb031xMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/resetpwd/index.vue").then(m => m.default || m)
  },
  {
    name: index0CaA51JrIYMeta?.name ?? "search",
    path: index0CaA51JrIYMeta?.path ?? "/search",
    meta: index0CaA51JrIYMeta || {},
    alias: index0CaA51JrIYMeta?.alias || [],
    redirect: index0CaA51JrIYMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: listbev0QQwLu8Meta?.name ?? "teacher-list",
    path: listbev0QQwLu8Meta?.path ?? "/teacher/list",
    meta: listbev0QQwLu8Meta || {},
    alias: listbev0QQwLu8Meta?.alias || [],
    redirect: listbev0QQwLu8Meta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/teacher/list.vue").then(m => m.default || m)
  },
  {
    name: viewdW6PB5dTKPMeta?.name ?? "teacher-view",
    path: viewdW6PB5dTKPMeta?.path ?? "/teacher/view",
    meta: viewdW6PB5dTKPMeta || {},
    alias: viewdW6PB5dTKPMeta?.alias || [],
    redirect: viewdW6PB5dTKPMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/teacher/view.vue").then(m => m.default || m)
  },
  {
    name: indexnHU00shtWxMeta?.name ?? "vip",
    path: indexnHU00shtWxMeta?.path ?? "/vip",
    meta: indexnHU00shtWxMeta || {},
    alias: indexnHU00shtWxMeta?.alias || [],
    redirect: indexnHU00shtWxMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/vip/index.vue").then(m => m.default || m)
  },
  {
    name: successMaDJMGov1KMeta?.name ?? "wx-success",
    path: successMaDJMGov1KMeta?.path ?? "/wx/success",
    meta: successMaDJMGov1KMeta || {},
    alias: successMaDJMGov1KMeta?.alias || [],
    redirect: successMaDJMGov1KMeta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/wx/success.vue").then(m => m.default || m)
  },
  {
    name: wxoLvAfqunM7Meta?.name ?? "wx-wx",
    path: wxoLvAfqunM7Meta?.path ?? "/wx/wx",
    meta: wxoLvAfqunM7Meta || {},
    alias: wxoLvAfqunM7Meta?.alias || [],
    redirect: wxoLvAfqunM7Meta?.redirect || undefined,
    component: () => import("/root/.jenkins/workspace/教师研修前端-生产环境-web/pages/wx/wx.vue").then(m => m.default || m)
  }
]